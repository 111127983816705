.bloggers {
    height: 100%;
    margin-top: 8em;
    width: 100%;
}

.bloggers__title {
    margin-bottom: 1em;
}

.bloggers__slider {
    position: relative;
    margin-bottom: 5em;
}

.bloggers .swiper-button-prev, .swiper-button-next {
    top: 50%
}


@media screen and (max-width: 1280px) {
    .bloggers {
        margin-top: 5em;
    }

}

@media screen and (max-width: 900px) {
    .bloggers__title {
        margin-bottom: 1.5em;
    }
}

@media screen and (max-width: 640px) {
  
}