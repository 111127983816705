.blogger-card {
    height: 100%;
}

.blogger-card__img {
    width: 100%;
    height: 100%;
    border-radius: 20px;
}

.blogger-card__blogger-data {
    margin: 1em 0;
}

.blogger-card__blogger-data>span {
    font-size: 1.3em;
    font-weight: 700;
}


.blogger-card__text {
    text-align: left;
}


@media screen and (max-width: 1440px) {
    .blogger-card__text {
        
    }

    .blogger-card__blogger-data {
        margin: 0.75em 0;
    }

}

@media screen and (max-width: 1280px) {
    .blogger-card__blogger-data span {
        
    }

    .blogger-card__text {
        
    }
}

@media screen and (max-width: 1152px) {
    .blogger-card__blogger-data {
        
    }

    .blogger-card__text {
        
    }

    .blogger-card__blogger-data span {
       
    }
}

@media screen and (max-width: 800px) {
    .blogger-card__blogger-data {
        
    }

    .blogger-card__text {
        font-size: 15px;
    }

    .blogger-card__blogger-data span {
        font-size: 17px;
    }
}


@media screen and (max-width: 640px) {
    .blogger-card__blogger-data span {
        font-size: 13px;
    }
    .blogger-card__text {
        font-size: 11px;
    }

}