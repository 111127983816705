.сontainer {
    width: 100%;
    max-width: 1520px;
    margin: 0 auto;
}

@media screen and (min-width: 1600px) {
    .container {
        max-width: 1600px;
    }
}

@media screen and (max-width: 1440px) {
    .container {
        max-width: 1400px;
    }
}

@media screen and (max-width: 1280px) {
    .container {
        max-width: 1240px;
    }
}

@media screen and (max-width: 960px) {
    .container {
        max-width: 98vw;
    }
}

@media screen and (max-width: 760px) {

}

@media screen and (max-width: 480px) {
    
}

@media screen and (max-width: 320px) {
    
}