.header {
    position: relative;
    padding: 2em 0 2.4em;
}

.header__wrapper {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    justify-content: space-between;
}


.navbar {

    display: flex;
    align-items: center;
    justify-items: stretch;
    justify-content: space-between;

}


.header__line {
    position: relative;
    margin-left: 2em;
}

.header__line .logo::after {
    content: ' ';
    position: absolute;
    width: 9999px;
    right: 0;
    bottom: -40px;
    border: 1px solid #FF5FBF;
    height: 1px;
}

.navbar__menu {
    display: flex;
}

.navbar__item {

    cursor: pointer;
    user-select: none;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.05em;
}

.navbar__item+.navbar__item {
    margin-left: 30px;
}

.header__btns {
    margin-left: 1em;
    margin-top: 0.5em;
    justify-self: flex-end;
}

.header__btns .button {
    font-size: 0.9em;
    padding: 1em 2em;
    border-radius: 70px;
}

.burger__btn {
    display: none;
    cursor: pointer;
}

.burger__btn span {
    display: flex;
    flex-direction: column;
}

.burger__btn span+span {
    margin-top: 4px;
}

.burger__btn span::before {
    content: " ";
    width: 33px;
    height: 5px;
    background-color: #515CD1;
}

.burger__btn span:nth-child(even) {
    margin-left: -6px;
    transition: margin 0.2s linear;
}

.burger__btn:hover span:nth-child(even) {
    margin-left: 0px;
}

.burger__menu {
    display: none;

    overflow: hidden;
    z-index: 20;
    position: absolute;

    top: 0;
    right: 0;
    align-items: center;
    justify-content: flex-end;
    width: 300px;
    height: auto;
    opacity: 0;
}

.burger__menu.visible {
    display: flex;
    opacity: 0;

    animation: fadeInFromNone 0.7s ease-in-out;
    animation-fill-mode: forwards;
}

.burger__menu.not-visible {
    display: none;
    opacity: 0;
    animation: fadeOutFromFlex 0.7s ease-in-out;
    animation-fill-mode: forwards;
}


@keyframes fadeInFromNone {
    0% {
        transform: translateX(300px);

        opacity: 0;
      }
    
      1% {
        
        opacity: 0;
      }
    
      100% {
       
        transform: translateX(0);
        opacity: 1;
      }
}

@keyframes fadeOutFromFlex {
    0% {
       
        opacity: 1;
      }
    
      99% {
       
        opacity: 0;
      }
    
      100% {
        transform: translateX(300px);
        
        opacity: 0;
      }
}


.burger__content {
    text-align: left;
    padding: 2em 3em;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    background-color: #ffffff;
    position: relative;
    display: inline-block;


    width: 100%;
    height: 100%;
}

.burger__content .header__btns {
    margin: 0;
    display: flex;
    flex-direction: column;
}

.burger__content .header__btns .button {
    width: 100%;
}

.burger__content .header__btns .button+.button {
    margin-left: 0;
    margin-top: 1em;
}

.burger__content .navbar__menu {
    text-align: center;
    margin-top: 2em;
    display: flex;
    flex-direction: column;
}

.burger__content .navbar__menu li {
    font-size: 1.5em;
}

.burger__content .navbar__menu li+li {
    margin-left: 0;
    margin-top: 1em;
}


.header__btns .button+.button {
    margin-left: 1em;
}

@media screen and (min-width: 1600px) {}

@media screen and (max-width: 1440px) {
    .header__wrapper {
        max-width: 1200px;
    }
}

@media screen and (max-width: 1280px) {
    .header {
        padding-top: 1.2em;
        padding-bottom: 1.1em;
    }

    .header__wrapper {
        max-width: 90vw;
    }

    .header__line {
        margin-left: 0;

    }


    .header__line .logo::after {
        bottom: -30px;
    }


    .navbar__item+.navbar__item {
        margin-left: 20px;
    }
}

@media screen and (max-width: 1050px) {
    .navbar__item {
        cursor: initial;
    }

    .navbar__menu {
        display: none;
    }

    .header__btns {
        display: none;
    }

    .burger__menu {
        display: flex;
    }

    .burger__btn {
        display: block;
    }
    .header__line {
        margin-left: 33px;
    }
}

@media screen and (max-width: 960px) {
    .header__wrapper {
        justify-content: space-between;
    }
    .header__line .logo::after {
        bottom: -20px;
    }
}

@media screen and (max-width: 760px) {}

@media screen and (max-width: 480px) {
    .header {
        padding-bottom: 0;
        padding-top: 0.5em;
    }

    .header__btns {
        margin-left: 0;
    }
    .header__line .logo::after {
        bottom: -10px;
    }
}

@media screen and (max-width: 320px) {
    .burger__menu {

        width: 100%;

    }
}