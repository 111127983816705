.end {
    margin-top: 9.5em;
}

.end__wrapper {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
}

.end h2,
.end h2 span {

    letter-spacing: 0.03em;
    text-transform: uppercase;

    color: #111111;
}

.end__btns {
    margin-top: 4.5em;
}

.end__btns .button+.button {
    margin-left: 3em;
}

.end__img {
    user-select: none;
    display: flex;
    justify-content: center;

    margin-top: 4em;
}

.end .num {
    margin-bottom: 0.4em;
    font-size: 95px;
    line-height: 1em;
    letter-spacing: 0.03em;
    text-transform: uppercase;

    color: #FF5FAB;
}

@media screen and (max-width: 1440px) {
    .end {
        margin-top: 8em;
    }
    .end__wrapper {
        max-width: 90vw;
    }
}

@media screen and (max-width: 1280px) {
    .end {
        margin-top: 6em;
    }
    .end h2,
    .end h2 span {
        font-size: 40px;
    }

    .end__btns {
        margin-top: 3em;
    }
    .end .num {
        font-size: 70px;
    }
}

@media screen and (max-width: 960px) {

    .end h2,
    .end h2 span {
        font-size: 33px;
    }
    .end .num {
        font-size: 60px;
    }
}

@media screen and (max-width: 760px) {
    .end {
        margin-top: 5em;
    }
    .end__btns {
        display: flex;
        flex-direction: column;
    }

    .end__btns .button+.button {
        margin-left: 0;
        margin-top: 1.2em;
    }

    .end__img {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-left: 0;
        margin-top: 4em;
    }

    .end h2,
    .end h2 span {
        font-size: 24px;
    }
    .end .num {
        font-size: 55px;
    }
}

@media screen and (max-width: 550px) {
    .end h2 span {
        display: block;
    }

    .end h2 br {
        display: none;
    }

    .end__btns {
        margin-top: 2.4em;
    }

    .end h2,
    .end h2 span {
        font-size: 30px;
    }

}

@media screen and (max-width: 480px) {

    .end h2,
    .end h2 span {
        font-size: 23px;
    }

    .end .num {
        font-size: 50px;
    }

}

@media screen and (max-width: 320px) {

    .end h2,
    .end h2 span {
        font-size: 22px;
    }
}