@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@400;500&display=swap');

.checklist {
  --img-container-padding: 40px;
  overflow: hidden;
  color: #111111;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(96, 109, 255, 0.2) 100%
  );
  height: 100vh;
}

.checklist__wrapper {
  width: 100%;
  max-width: 1520px;
  margin: 0 auto;
}

.checklist__logo {
  text-align: left;
  margin-bottom: 40px;
}
.mobile.checklist__logo {
  display: none;
}

.checklist__main {
  margin: 0 72px;
  margin-top: 100px;
}

.checklist__title {
  font-family: 'Oswald', sans-serif;
  font-size: 80px;
  font-style: normal;
  font-weight: 400;
  line-height: 1em;
  letter-spacing: 0.8px;
  text-transform: uppercase;
}

.checklist__title .purple {
  font-weight: 500;
}

.checklist__title div + div {
  margin-top: 22px;
}

.checklist__subtitle {
  position: relative;
  margin-top: 44px;
  margin-bottom: 60px;

  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.78px;
}

.checklist__arrows {
  position: absolute;
  right: 0;
  top: 25%;
  bottom: 25%;
}

.checklist__leftside {
  text-align: left;
}

.checklist__rightside {
  z-index: 2;
  position: relative;

  padding-top: var(--img-container-padding);
  padding-bottom: calc(2 * var(--img-container-padding));

  background: #ffffff;
  border-top-left-radius: 60px;
  border-bottom-left-radius: 60px;
}

.checklist__rightside::before {
  left: 0;
  top: 0;
  content: ' ';
  z-index: -1;
  position: absolute;
  background: #ffffff;
  border-top-left-radius: 60px;
  border-bottom-left-radius: 60px;
  height: 100%;
  width: 50vw;
}

.checklist__rightside__img-container {
  position: relative;
  width: 100%;
  max-width: 500px;
}
.checklist__rightside__img-container .img {
  margin-top: calc(-1.5 * var(--img-container-padding));
  margin-left: -60px;
}

.checklist__rightside__top-title {
  text-align: left;
  top: 0;
  right: calc(2 * var(--img-container-padding));
  position: absolute;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.48px;
}

.checklist__rightside__bottom-title {
  position: absolute;
  right: 0;
  bottom: -47px;
  text-align: center;
  font-family: 'Krona One';
  font-size: 10px;
  font-weight: 400;
  letter-spacing: 0.1px;
  text-transform: uppercase;
}

.checklist__rightside__bottom-title span {
  display: block;
}

.checklist__rightside__bottom-title span + span {
  margin-top: 7px;
}

.checklist .mobile.button {
  display: none;
}

.mobile.checklist__rightside__bottom-title {
  display: none;
}

.checklist__form {
  width: 100%;
  max-width: 440px;

  margin: 0 auto;
  margin-top: 100px;
  padding: 24px 72px;

  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  border: 1px solid #515cd1;
}

.checklist__form input {
  width: 100%;
  padding: 15px 24px;
  border: 1px solid #515cd1;
}

.checklist__form .decor__free {
  align-self: flex-start;
  padding: 10px 20px;
  border-radius: 18.5px;
  background: #515cd1;
  color: #ffffff;

  text-align: center;
  font-family: 'Geometria';
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.12px;
  text-transform: uppercase;
}

.checklist__form .form__subtitle {
  margin-top: 28px;
  margin-bottom: 8px;
  text-align: center;
  font-family: 'Geometria';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.12px;
}

.checklist__form .form__title {
  margin-bottom: 24px;
  font-family: 'Geometria';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.16px;
  text-transform: uppercase;
}

.checklist__form .input + .input {
  margin-top: 16px;
}

.checklist__form .form__policy {
  margin-top: 12px;
  margin-bottom: 28px;
  text-align: center;
  font-family: Geometria;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.1px;
}

.checklist__finish .button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.checklist__rightside__top-title {
  right: 40px;
}

.checklist__finish .checklist__rightside {
  background: transparent;
}

.checklist__finish .checklist__rightside::before {
  background: transparent;
}

@media screen and (max-width: 1440px) {
  .checklist__wrapper {
    max-width: 1400px;
  }
}

@media screen and (max-width: 1280px) {
  .checklist__wrapper {
    max-width: 1240px;
  }
  .checklist__main {
    margin-left: 30px;
    margin-right: 30px;
  }
  .checklist__title {
    font-size: 55px;
  }
  .checklist__title div + div {
    margin-top: 16px;
  }
  .checklist__subtitle {
    position: relative;
    margin-bottom: 60px;

    font-size: 20px;
  }

  .checklist__rightside__img-container {
    max-width: 400px;
  }
}

@media screen and (max-width: 960px) {
  .checklist__wrapper {
    max-width: 95vw;
  }
  .checklist__main {
    margin-top: 10px;
  }
  .mobile.checklist__logo {
    display: block;
    position: relative;
    margin-bottom: 10px;
  }
  .desktop.checklist__logo {
    display: none;
  }
  .checklist .mobile.button {
    display: block;
    margin-top: 20px;
  }
  .checklist .desktop.button {
    display: none;
  }
  .checklist .row {
    flex-direction: column;
    align-items: center;
  }

  .checklist__subtitle {
    width: 100%;
    font-size: 15px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .checklist__subtitle div {
    margin-right: 40px;
  }
  .checklist__main .checklist__subtitle br {
    display: none;
  }
  .checklist__finish .checklist__subtitle br {
    display: initial;
  }
  .checklist__subtitle .purple {
    display: block;
  }

  .checklist__arrows {
    transform: rotate(90deg);
  }

  .checklist .button {
    display: block;
    margin: 0 auto;
  }

  .checklist__rightside {
    background: transparent;
    margin-top: 40px;
  }

  .checklist__rightside::before {
    background: transparent;
  }

  .checklist__leftside .buttton {
    display: none;
  }

  .checklist__rightside__bottom-title {
    display: none;
  }
  .mobile.checklist__rightside__bottom-title {
    display: block;
    bottom: 0;
  }

  .checklist__rightside__img-container {
    width: 100%;
    max-width: 500px;
  }
  .checklist__rightside__img-container .img {
    margin-top: calc(-1.5 * var(--img-container-padding));
    margin-left: 0;
  }
}

@media screen and (max-width: 760px) {
  .checklist__title {
    font-size: 38px;
  }

  .checklist__arrows {
    right: -10px;
  }
  .checklist__rightside__top-title {
    right: 0;
  }

  .checklist__finish .checklist__rightside__top-title {
    display: none;
  }
}

@media screen and (max-width: 480px) {
  .checklist__form {
    padding-left: 20px;
    padding-right: 20px;
  }
  .checklist__form .form__title {
    font-size: 14px;
  }
  .checklist__finish .checklist__title {
    font-size: 32px;
  }

  .checklist__form .input {
    font-size: 16px;
  }
}

@media screen and (max-width: 375px) {
  .checklist__main {
    margin-left: 20px;
    margin-right: 20px;
  }
  .checklist__main.checklist__finish {
    margin-left: 0;
    margin-right: 0;
  }
  .checklist__title {
    font-size: 32px;
  }
  .checklist__subtitle {
    font-size: 12px;
  }
}

@media screen and (max-width: 320px) {
}
