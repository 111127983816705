.platforms {
    margin-top: 10em;
}

.platforms__wrapper {
    width: 100%;
    max-width: 1450px;
    margin: 0 auto;
}

.platforms h2 {
    letter-spacing: 0.03em;
}

.platforms__img {
    margin: 0 auto;
    margin-top: 5em;
}

.platforms__img img {
    width: auto;
}

.platforms__img .mobile {
    display: none;
}

@media screen and (max-width: 1440px) {
   .platforms__wrapper {
    max-width: 95vw;
   }
}

@media screen and (max-width: 1280px) {
    
    
}

@media screen and (max-width: 960px) {
    
}

@media screen and (max-width: 760px) {
    .platforms {
        margin-top: 5em;
    }
    .platforms__img {
        margin-top: 2em;
    }
  
}

@media screen and (max-width: 480px) {
    .platforms__img .mobile {
        display: initial;
    }
    .platforms__img .desktop {
        display: none;
    }
}

@media screen and (max-width: 320px) {}