.button {
  position: relative;
  display: inline-block;
  width: auto;

  z-index: 3;

  white-space: nowrap;
  cursor: pointer;
  user-select: none;

  text-align: center;
  vertical-align: middle;
  font-family: 'Lato', sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.2em;
  letter-spacing: 0.07em;
  text-transform: uppercase;
  padding: 1.4em 1.9em;

  color: #ffffff;

  border: none;
  background-image: linear-gradient(
    to right,
    hsl(235, 58%, 57%),
    hsl(332, 100%, 69%)
  );
  transition: transform ease-in 0.1s;

  border-radius: 100px;
}

.button:disabled {
  
  opacity: 0.7;
  transition: none;
}

.button:disabled::before {
  opacity: 0.7;
  transition: none;
  background-image: linear-gradient(
    to right,
    hsl(235, 58%, 57%),
    hsl(332, 100%, 69%)
  );
}

.button:disabled:hover {
  transform: scale(1);
  transition: none;
  
}

.button:disabled:hover::before {
  background-image: none;
  transition: none;
  opacity: 1;
}

.button:disabled:active {
  transform: scale(1);
}

.button_light {
  color: #1c1c1c;
  background: none;
  border: 1.5px solid #ff5fab;
}

.button_rectangle {
  border-radius: 8px;
}

.button_purple {
  background: #515cd1;
}

.button.button_purple::before {
  background: #515cd1;
}

.button_purple:hover {
  background: #515cd1;
}

.button_purple:hover::before {
  background: #515cd1;
}

.button:active {
  transform: scale(0.97);
}

.button::before {
  border-radius: 40px;
  position: absolute;
  content: '';
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(
    to right,
    hsl(235, 58%, 57%, 1%),
    hsl(329, 89%, 67%, 100%)
  );
  z-index: -1;
  transition: opacity 0.4s ease-in;
  opacity: 0;
}

.button_reverse {
  background: linear-gradient(to left, hsl(235, 58%, 57%), hsl(332, 100%, 69%));
}

.button_reverse::before {
  background: linear-gradient(
    to left,
    hsl(235, 58%, 57%, 1%),
    hsl(329, 89%, 67%, 100%)
  );
}

.button:hover::before {
  opacity: 1;
  border-radius: 40px;
}

.button:hover {
  transform: scale(1.05);
}

.button:active {
  transform: scale(0.95);
}

@media screen and (max-width: 1280px) {
  .button {
    font-size: 1em;
  }
}

@media screen and (max-width: 1152px) {
  .button {
    font-size: 0.8em;
  }
}

@media screen and (max-width: 980px) {
}

@media screen and (max-width: 760px) {
  .button {
    font-size: 17px;
  }
}

@media screen and (max-width: 480px) {
  .button {
    font-size: 16px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media screen and (max-width: 320px) {
}
