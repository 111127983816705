.user-flow {
    margin-top: 9em;
}

.user-flow__wrapper {
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
}

.user-flow__headers {
    position: relative;
}

.user-flow__headers::before {
    content: " ";
    position: absolute;
    width: 2.1em;
    height: 100%;
    left: 0;
    background-color: #515CD1;
}

.user-flow__headers h2 {
    letter-spacing: 0.03em;
    line-height: 1em;
}

.user-flow__headers h3 {

    margin-top: 1em;

    font-weight: 700;
    font-size: 35px;
    line-height: 1.3em;

    letter-spacing: 0.05em;

    background: linear-gradient(89.76deg, #515CD1 0.21%, #FF5FAB 99.79%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.user-flow__img {
    margin-top: 1.5em;
}

.user-flow__text {
    margin-top: 3em;
}

.user-flow__counters {
    display: flex;
    justify-content: center;
}

.user-flow__counters div {}

.user-flow__counters div+div {
    margin-left: 4.5em;
}

.user-flow__num {
    
    
    font-family: monospace;
    font-style: normal;
    font-weight: 700;
    font-size: 95px;
    line-height: 70px;

    letter-spacing: 0.03em;
    text-transform: uppercase;

    color: #FF5FAB;
}

.user-flow__num+div {
    margin-top: 1em;

    font-weight: 700;
    font-size: 22px;
    line-height: 44px;
    /* identical to box height, or 200% */

    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;

    color: #1C1C1C;
}

.user-flow__text h4 {
    margin-top: 1.5rem;
    margin-bottom: 2em;

    font-weight: 700;
    font-size: 35px;
    line-height: 44px;
    /* identical to box height, or 126% */

    letter-spacing: 0.056em;
    text-transform: uppercase;

}

.user-flow__img .mobile {
    display: none;
}

.user-flow__headers h2 br {
    display: none;
}

.blogger.user-flow__text .user-flow__counters {
    margin-bottom: 3em;
}

.blogger.user-flow__text .user-flow__counters div div {
    font-size: 1.1rem;
    line-height: 1.3em;
}

.blogger.user-flow__text .user-flow__footer {
    
    position: relative;
    margin-top: 4.5em;
    padding: 2.5em 0 4em;
}
.blogger.user-flow__text .user-flow__footer h4 {
    margin-bottom: 1.5em;
}

.blogger.user-flow__text .user-flow__footer::before {
    background-color: #FFFFFF;
    position: absolute;
    content: " ";
    z-index: -1;
    width: 100vw;
    height: 100%;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);

    border-top: 1px solid #FF5FAB;
    border-bottom: 1px solid #515CD1;
}

@media screen and (max-width: 1440px) {
    .user-flow__wrapper {
        max-width: 85vw;
    }
}

@media screen and (max-width: 1280px) {
    .user-flow__headers h3 {
        margin-top: 0.6em;
        line-height: 1em;
    }

    .user-flow__num {
        font-size: 80px;
    }

    .user-flow__text h4 {
        font-size: 30px;
    }

}

@media screen and (max-width: 960px) {
    .user-flow__headers h3 {
        margin-top: 0.5em;
        line-height: 0.9em;
    }

    .user-flow__text h4 {
        font-size: 25px;
    }

    .user-flow__num+div {
        font-size: 20px;
    }

    .blogger.user-flow__text .user-flow__footer h4 {
        line-height: 1.3em;
    }
}

@media screen and (max-width: 760px) {
    .user-flow__headers::before {
        width: 1.3em;
    }

    .user-flow__headers h3 {
        line-height: 0.75em;
    }

    .user-flow__num+div {
        margin-top: 0;
        font-size: 18px;
    }

    .user-flow__num {
        font-size: 50px;
    }

    .user-flow__counters {
        justify-content: space-evenly;
    }

    .user-flow__counters div+div {
        margin-left: 0em;
    }
}

@media screen and (max-width: 600px) {
    .user-flow__img .desktop {
        display: none;
    }

    .user-flow__img .mobile {
        display: initial;
    }

    .user-flow__headers h3 {
        display: none;
    }

    .user-flow__headers h2 {
        line-height: 2em;
        background: linear-gradient(92.14deg, #515CD1 2.11%, #FF5FAB 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
    }

    .blogger.user-flow__text .user-flow__footer h4 {
        font-size: 20px;
        line-height: 1.3em;
    }

}

@media screen and (max-width: 480px) {
    .user-flow__counters {
        flex-direction: column;
    }

    .user-flow__headers h2 br {
        display: initial;
    }

    .user-flow__headers h2 {
        line-height: 1.1em;
    }



    .user-flow__num {
        font-size: 50px;
        line-height: 1.1em;
    }

    .user-flow__num+div {
        font-size: 14px;
        line-height: 3em;
    }

    .user-flow__text h4 {
        font-size: 22px;
        margin-top: 0.5em;
        line-height: 1.3em;
    }


}

@media screen and (max-width: 320px) {}