.discount-widget {
    overflow: hidden;
    z-index: 20;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
}

.discount-widget__content {
    text-align: left;
    padding: 2em 3em;
    background-color: #ffffff;
    border-radius: 2em;
    position: relative;
    display: inline-block;
    margin: 1em 0;

    width: 100%;
    max-width: 70vw;
    height: auto;
}

.discount-widget__content img+.content {
    position: absolute;
    top: 0;
    left: 0;
}

.content {
    height: 100%;
    width: 100%;
}

.discount-widget__flex-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.discount-widget__bg {}

.discount-widget__close {
    cursor: pointer;
    position: absolute;
    width: 40px;
    height: 40px;
    border: none;
    border-radius: 50%;
    border: 1px solid #FF5FAB;
    background: #ffffff;
    padding: 0;
    right: 2%;
    top: 4%;

}

.discount-widget__close img {
    
    width: 60%;
    text-align: center;
    vertical-align: middle;
}

.discount-widget__header h3 {
    font-weight: bold;
}

.discount-widget__body {
    margin-top: 0.6em;

}

.discount-widget__body span {
    font-weight: 700;
}

.discount-widget__body div {
    line-height: 1.2em;
}

.discount-widget__body div+div {
    margin-top: 1em;
}

.discount-widget__body div a {
    text-decoration: underline;
}

.discount-widget__body div a:hover {
    color: #515CD1;
}

.discount-widget__body input {
    margin: 1em 0;
    font-size: 1.25em;
    height: 2.5em;
    width: 80%;
    padding-left: 20px;

    border-radius: 10px;
    border: #ffffff;
    outline: none;
}

.discount-widget__body button {
    width: 80%;
    border-radius: 10px;
    text-transform: none;
    font-size: 1.5em;
    background-color: #3A1A3F;
    border-color: #3A1A3F;
}

@media screen and (max-width: 1280px) {
    .discount-widget__body button {
        padding: 1em 0;
    }

}

@media screen and (max-width: 1440px) {
   
}

@media screen and (max-width: 980px) {

    .discount-widget__content {
        
    }

    .discount-widget__body button {
        padding: 0.75em 0;
        font-size: 1.25em;
    }
}

@media screen and (max-width: 840px) {

    .discount-widget__content {
    }

}

@media screen and (max-width: 640px) {
    .discount-widget__close {
        width: 40px;
        height: 40px;
    }

    .discount-widget__content {
       
    }

}

@media screen and (max-width: 520px) {

    .discount-widget__content {
       
    }

}

@media screen and (max-width: 425px) {

    .discount-widget__content {
        font-size: 12px;
       
    }

}

@media screen and (max-width: 360px) {
    .discount-widget__content {
     

    }
}

@media screen and (max-width: 360px) {
    .discount-widget__content {
        max-width: 80vw;
    }
}