.feedback {
    margin-top: 9em;
}

.feedback__wrapper {
    width: 100%;
    max-width: 95vw;
    margin: 0 auto;
}

.feedback h2 {

    line-height: 1.25em;
    /* identical to box height, or 117% */

    letter-spacing: 0.03em;

}

.feedback__slider {
    position: relative;
    margin-top: 5.5em;
}




.feedback__card {
    margin: 20px 0;
    position: relative;
    width: 26.5em;
    height: 625px;
    
    padding: 2.4em;

    user-select: none;
    cursor: grab;


    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;

    background-color: rgba(96, 109, 255, 0.15);
}

.feedback__card:active {
    cursor: grabbing;
}

.feedback__card.column-reverse {
   padding-top: 1.5em;
   padding-bottom: 1.5em;
}





.feedback__card span {
    padding: 1em 0;
    font-family: 'Geometria';
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 125%;

    letter-spacing: 0.01em;
    text-transform: uppercase;

    color: #515CD1;
}

.feedback__card p {
    text-align: left;

    font-style: italic;
    font-weight: 400;
    font-size: 17px;
    line-height: 125%;
    /* or 21px */

    letter-spacing: 0.01em;

    color: #1C1C1C;
}


.feedback__card::before {
    content: " ";
    position: absolute;
    z-index: -1;
    border: 1px solid #515CD1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.feedback__card.border_left {
    margin-left: 10px;
}

.feedback__card.border_right {
    margin-right: 10px;
}

.feedback__card.border_left::before {
    top: -20px;
    left: -10px;
}

.feedback__card.border_right::before {
    top: 20px;
    left: 10px;

}

.feedback .swiper {
    width: 90%;
}

.feedback .swiper-button-prev, .feedback .swiper-button-next {
    top: 50%
}







@media screen and (max-width: 1440px) {
    .feedback__wrapper {
        max-width: 90vw;
    }

    .feedback__card {
        height: 625px;
    }
}

@media screen and (max-width: 1280px) {
    .feedback {
        margin-top: 7em;
    }

    .feedback__slider {
        margin-top: 4em;
    }
}

@media screen and (max-width: 960px) {
    .feedback {
        margin-top: 5em;
    }

    .feedback__slider {
        margin-top: 3em;
    }

    .feedback__card {
        height: 500px;
    }

    .feedback__card p {
        font-size: 14px;
    }
}

@media screen and (max-width: 760px) {
    .feedback {
        margin-top: 4em;
    }

    .feedback__card {
        padding: 28px 38px 38px 28px;
        margin: 10px 0;
        width: 360px;
        height: 540px;
    }
    .feedback__card.border_left::before {
        top: -10px;
    }

    .feedback__card.border_right {
        padding-right: 28px;
        padding-left: 38px;
    }
    
    .feedback__card.border_right::before {
        top: 10px;
    }

    .feedback__card span {
        font-size: 17px;
        padding: 15px 0;
    }

    .feedback__slider {
        margin-top: 1.5em;
    }
}

@media screen and (max-width: 480px) {
    .feedback__card {
        width: 250px;
        padding: 17px;
        height: 465px;
    }

    .feedback__card.border_right {
        padding-right: 17px;
        padding-left: 27px;
    }

    .feedback__card.border_left {
        padding-left: 17px;
        padding-right: 27px;
    }

    .feedback__card span {
        padding: 10px 0;
        font-size: 15px;
    }

    .feedback {
        margin-top: 5em;
    }

    .feedback__card p {
        font-size: 12px;
    }

    .feedback__slider {
        margin-top: 1.5em;
    }

}

@media screen and (max-width: 320px) {}