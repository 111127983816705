.logo {
    display: block;
    font-family: 'Krona One', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 50px;
    line-height: 63px;

    user-select: none;

    background: linear-gradient(92.14deg, #515CD1 2.11%, #FF5FAB 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    justify-self:flex-end;
    cursor: pointer;
}

@media screen and (max-width: 1280px) {
    .logo {
        font-size: 45px;
    }
}

@media screen and (max-width: 960px) {
    .logo {
        font-size: 45px;
    }
}

@media screen and (max-width: 760px) {
    .logo {
        font-size: 40px;
    }
}

@media screen and (max-width: 480px) {
    .logo {
        font-size: 30px;
    }
}

@media screen and (max-width: 320px) {
    .logo {
        font-size: 28px;
    }
}