.advantages {
    width: 100%;
    height: 100%;
    margin-top: 9.5em;
}

.advantages__wrapper {
    position: relative;
    width: 100%;
    max-width: 93vw;
    margin: 0 auto;
}

.advantages h2 {
    letter-spacing: 0.03em;
    margin-bottom: 1.2em;
}

.advantages .swiper-button-prev, .advantages .swiper-button-next {
    top: 74%;
}


@media screen and (max-width: 1440px) {
    .advantages__wrapper {
        max-width: 95vw;
    }
  }
  
  
  @media screen and (max-width: 1280px) {
  
  
  }
  
  @media screen and (max-width: 960px) {
  
  
  }
  
  @media screen and (max-width: 760px) {

  }
  
  @media screen and (max-width: 480px) {

  }
  
  @media screen and (max-width: 320px) {
 
  }