.footer {
    padding-top: 4.3em;
    padding-bottom: 3.5em;
    z-index: 2;
    margin-top: -9em;
    position: relative;
    width: 100%;
    height: 20em;
}

.footer::before {
    content: " ";
    position: absolute;
    z-index: -1;
    width: 100vw;
    height: 100%;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);

    background: #E7E9FF;
}

.footer__wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
}

.footer__nav {
    text-align: left;
    display: flex;

    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.05em;
}

.footer__nav div {
    display: flex;
    flex-direction: column;
}

.footer__nav span {
    cursor: pointer;
    transition: color 0.15s linear;
}

.footer__nav span:hover {
    color: #FF5FBF;
}

.footer__nav div+div {
    margin-left: 1.5em;
}

.footer__nav span+span {
    margin-top: 0.6em;
}

.footer__logo {
    display: flex;
    flex-direction: column;
    margin-left: 3em;
}

.footer__logo .logo::after {
    display: none;
}

.footer__logo__subtitle {
    margin-top: 0.9em;

    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    letter-spacing: 0.05em;
}

.footer__law {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 3.5em;

    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.05em;

    color: #1C1C1C;
}

.footer__law span+span {
    margin-top: 0.7em;
}

.footer__law span {
    opacity: 0.5;
    cursor: pointer;
    transition: opacity 0.2s linear;
}

.footer__law span:hover {
   opacity: 1;
}

.footer__contacts {}


.footer__media {
    display: flex;
    align-items: center;
    justify-content: center;
}
.footer__media div {
    cursor: pointer;
    transition: transform 0.2s linear;
}

.footer__media div:hover {
    transform:  scale(1.05);
}

.footer__media div+div {
    margin-left: 0.5em;
}

.footer__contact_contacts {
    display: flex;
    justify-content: space-between;

    margin-top: 1.1em;

    line-height: 125%;
    /* or 21px */

    letter-spacing: 0.01em;
    text-decoration-line: underline;


}

.footer__contact_contacts span+span{
    margin-left: 1em;
}

.mobile.footer__law {
    display: none;
}

@media screen and (max-width: 1440px) {
    .footer__wrapper {
        max-width: 90vw;
    }
}


@media screen and (max-width: 1280px) {
    .footer {
        margin-top: -6em;
    }

    .footer__logo {
        margin-right: 5em;
    }

    .footer__law {
        flex-direction: column;
    }

    .footer__law span+span {
        margin: 0;
    }

    .footer__contact_contacts {
        font-size: 1.2em;
        flex-direction: column;
        align-items: center;
    }

    .footer__contact_contacts span+span{
        margin-left: 0;
        margin-top: 0.5em;
    }



}


@media screen and (max-width: 960px) {
    .footer {
        height: 100%;
        margin-top: -4em;
    }

    .footer__wrapper {
        align-items: center;
        flex-direction: column;
    }

    .footer__nav {
        order: 2;
        margin-bottom: 3em;
    }

    .footer__logo {
        order: 1;
        margin-bottom: 2.5em;
        margin-right: 0;
        margin-left: 0;
    }

    .desktop.footer__law {
        display: none;
    }
    .mobile.footer__law {
        order: 4;
        display: flex;
        flex-direction: column;
    }

    .footer__law {
        margin-top: 1em;
    }

    .footer__law span+span {
        margin-top: 1em;
    }

    .footer__media {
       justify-content: center;
    }

    .footer__contact {
        order: 3;
        margin-top: 0em;
        margin-bottom: 2em;
    }

    .footer__contact_contacts {
        flex-direction: row;
        justify-content: space-between;
    }
    .footer__contact_contacts span+span{
        margin-left: 1em;
        margin-top: 0;
    }
}

@media screen and (max-width: 760px) {
    .footer__wrapper {
        flex-direction: column;
        align-items: center;
    }

    .footer__logo .logo {
        font-size: 50px;
    }

    .footer__media div {
        width: 45px;

    }


}

@media screen and (max-width: 480px) {

    .footer {
        padding-bottom: 7em;
    }

    .footer__nav, .footer__contact_contacts {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .footer__contact_contacts span+span{
        margin-left: 0;
        margin-top: 0.5em;
    }

    .footer__nav div+div {
        margin: 0;
        margin-top: 0.6em;
    }

    
}

@media screen and (max-width: 320px) {}