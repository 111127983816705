.AdvantagesCard {
    width: 22rem;
    height: 12rem;
    padding: 2.2rem 1.9rem;
    padding-bottom: 0;
    border: 4px solid #FF5FAB;
    cursor:grab;
    user-select: none;
}

.AdvantagesCard:active {
    cursor: grabbing;
}

.AdvantagesCard span {


    font-style: normal;
    font-weight: 500;
    font-size: 1.5rem;

    text-align: center;
    letter-spacing: 0.06rem;
    text-transform: uppercase;

}

.AdvantagesCard hr {
    margin-top: 1.1rem;
    margin-bottom: 1.2rem;
    width: 90%;
    align-self: center;
    background-color: #1C1C1C;
}

.AdvantagesCard p {
    
    text-align: center;
    
    line-height: 1.25rem;
    letter-spacing: 0.01rem;
}

.AdvantagesCard__theme_filled {
    border-color: #515CD1;
    background-color: #515CD1;
    color: #FFFFFF;
}

.AdvantagesCard__theme_filled hr {
    background-color: #FFFFFF;
}

@media screen and (max-width: 768px) {
    .AdvantagesCard {
        padding: 20px;
        width: 300px;
        height: 160px;
    }

    .AdvantagesCard p {
        font-size: 14px;
    }

    .AdvantagesCard span {
        font-size: 17px;
        line-height: 19px;
    }
}

@media screen and (max-width: 480px) {
    .AdvantagesCard {
        padding: 20px;
        width: 250px;
        height: 140px;
    }

    .AdvantagesCard span {
        font-size: 15px;
        line-height: 16px;
    }
    .AdvantagesCard p {
        font-size: 11px;
        line-height: 13px;
    }
}