.faq {
    margin-top: 9em;
}

.faq__wrapper {
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
}

.faq h2 {
    letter-spacing: 0.03em;
    color: #515CD1;
}

.faq__body {
    margin-top: 4.5em;
}

.faq__body details {
    position: relative;
}

.faq__body details+details {
    margin-top: 1.9em;
}

.faq__body details::before {
    content: " ";
    position: absolute;
    z-index: -1;
    border: 1px solid #515CD1;
    width: 100%;
    height: 100%;
    top: 0.6em;
    left: 0.5em;
}

.faq__body summary {
    cursor: pointer;
    padding: 2em;
    position: relative;
    background: rgba(96, 109, 255, 0.15);
    display: flex;
    align-items: center;
}

.faq__body details summary .arrow {
    width: 20px;
    height: 20px;
    border-right: 3px solid #111111;
    border-bottom: 3px solid #111111;
    transform: rotate(135deg);
    margin-left: auto;
    margin-right: 1em;

    transition: transform 0.2s linear;
}

.faq__body details[open] summary .arrow {
    transform: rotate(45deg);
}

.faq__body summary h3 {
    font-size: 24px;
    line-height: 125%;

    letter-spacing: 0.01em;
    text-transform: uppercase;
    text-align: left;

    color: #000000;
}


.faq__body .num {
    margin-right: 1.4em;
    font-size: 35px;
    line-height: 125%;

    letter-spacing: 0.01em;
    text-transform: uppercase;

    color: #515CD1;
}

.faq__body details p {

    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 125%;

    letter-spacing: 0.01em;

    text-align: left;
    padding: 2em 7.5em 1.1em;
}

@keyframes fadeInDown {
    0% {
        opacity: 0;
        transform: translateY(-1.25em);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeInUp {
    0% {
        opacity: 1;
        transform: translateY(0);
    }

    100% {
        opacity: 0;
        transform: translateY(1.25em);
    }
}

.faq__body details[open] p {
    animation-name: fadeInDown;
    animation-duration: 0.5s;
}

.faq__body details p {
    animation-name: fadeInUp;
    animation-duration: 0.5s;
}

@media screen and (max-width: 1440px) {
    .faq__wrapper {
        max-width: 90vw;
    }
}

@media screen and (max-width: 1280px) {}

@media screen and (max-width: 960px) {
    .faq__body .num {
        font-size: 2em;
    }

    .faq__body summary h3 {
        font-size: 1.4em;
    }

    .faq__body summary h3 span {
        font-size: 1.2em;
    }

    .faq__body details p {
        padding: 2em 5em 1.1em;
    }
}

@media screen and (max-width: 820px) {
    .faq__body summary {
        cursor: default;
    }

    .faq__body details summary .arrow {
        display: none;
    }

}

@media screen and (max-width: 760px) {
    .faq {
        margin-top: 5em;
    }

    .faq__body {
        margin-top: 3em;
    }

    .faq__body summary h3 {
        font-size: 1.2em;
        text-align: left;
    }

    .faq__body summary h3 span {
        font-size: 1.2em;
    }

    .faq__body details p {
        padding: 2em 4em 1.1em;
    }
}

@media screen and (max-width: 480px) {

    .faq h2 {
        font-size: 23px;
    }

    .faq__body summary h3,
    .faq__body summary h3 span {
        font-size: 14px;
    }

    .faq__body summary {
        padding: 24px 30px;
    }

    .faq__body .num {
        font-size: 20px;
        margin-right: 15px;
    }

    .faq__body details p {
        font-size: 14px;
        padding: 1em 1.5em;
    }
}

@media screen and (max-width: 320px) {}