.about {
    padding-top: 4em;
}

.about__light {
    z-index: -1;
    position: absolute;
    content: url('../assets/light_about.svg');
    height: 100%;
    bottom: 0;
    top: -20%;
    left: -13%;
}

.about__text1 .title__decor_arrow {
    bottom: -110%;
    animation: fromLeftAboutArrows 0.7s ease-out;
    animation-delay: 0.7s;
    animation-fill-mode: forwards;
}

@keyframes fromLeftAboutArrows {
    0% {
        left: -30%;
        opacity: 0;
    }

    100% {
        opacity: 1;
        left: 0;
    }
}

.about__wrapper {
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
}

.about .h1-like-title {

    margin-bottom: 0.4em;
}

.about__subtitle {
    align-items: center;
    justify-content: center;
    display: flex;
}

.about__subtitle h3 {
    margin-left: 1.6em;
    font-weight: 400;
    font-size: 37px;
    line-height: 44px;

    letter-spacing: 0.03em;
}



.about__subtitle span {
    display: block;
    width: 2.7rem;
    height: 0.3rem;
    background-color: rgba(28, 28, 28, 0.5);
}

.about__body {
    margin-top: 3.6em;
    text-align: left;
    display: flex;
    justify-content: center;
}


.about__text1 h4 span {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 900;
    font-size: 30px;
    line-height: 1.2em;
    letter-spacing: 0.06em;
    text-transform: uppercase;

    background: linear-gradient(89.76deg, #515CD1 0.21%, #FF5FAB 99.79%);
    background-clip: text;
    -webkit-text-fill-color: transparent;

}

.about__text1 h4::before {
    position: absolute;
    content: url('../assets/love.svg');
    right: 7%;
    top: 25%
}

.about__text1 {
    position: relative;
    padding: 3em 0 3em 4.1em;
    height: 100%;
}

.about__text1::before {
    content: "";
    z-index: -1;
    position: absolute;
    border: 1px solid #515CD1;
    width: 125%;
    max-width: 700px;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
}

.about__text1 div {
    width: 100%;
    margin-right: 10em;
    margin-top: 1.5em;

    line-height: 20px;
    letter-spacing: 0.02em;
}

.about__img {
    margin-top: 3em;
}

.about__text2 {
    position: relative;
    align-self: flex-end;
    margin-left: 3.7em;
}

.about__text2 h4 {
    position: relative;
    margin-bottom: 1.1em;

    font-family: 'Geometria';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 1.2em;
    letter-spacing: 0.02em;
    text-transform: uppercase;

    color: #515CD1
}

.about__text2 ol {
    counter-reset: item;
    list-style-type: none;
    margin-bottom: 4em;
}

.about__text2 li+li {
    margin-top: 1em;
}

.about__text2 ol li {
    display: block;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
}

.about__text2 ol li:before {
    content: "0" counter(item);
    counter-increment: item;
    margin-right: 19px;

    font-family: 'Neue Machina';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 1em;

    letter-spacing: 0.02em;
    text-transform: uppercase;

}

.about__text2 .button {
    margin-left: -0.7em;
}

.about__subtitle h3 br {
    display: none;
}

.about__photos {
    z-index: -1;
    position: absolute;
    content: url('../assets/photos.svg');
    top: 85%;
    right: 10%;
}

.about__planet {
    z-index: -1;
    position: absolute;
    content: url('../assets/planet.svg');
    top: -40%;
    left: -5%;
}

#spec {
    display: none;
}

@media screen and (max-width: 1440px) {
    .about__wrapper {
        max-width: 95vw;
    }

    .about .h1-like-title {
        margin-bottom: 0.2em;
    }

    .about__subtitle h3 {
        font-size: 2em;
    }

    .about__text2 {
        align-self: flex-end;

    }
}


@media screen and (max-width: 1280px) {
    .about__planet {
        top: -50%;
        left: -5%;
    }

    .about__photos {
        width: 140px;
        height: auto;
    }

    .about__subtitle h3 {
        font-size: 1.5em;
    }

    .about__body {
        justify-content: space-between;
        margin-top: 3em;
    }

    .about__text1::before {
        width: 50vw;
    }

    .about__text1 {
        width: 40%;
    }

    .about__text1 div {

        margin-top: 1em;
        margin-right: -10vw;

    }

    .about__body .about__text1 h4 {
        display: inline;
    }

    .about__text1 h4 {}

    .about h4,
    .about h4 span {
        font-size: 23px;
    }

    .about__text2 {

        margin-left: 1.5em;
    }

    .about__text2 ol li {
        font-size: 1em;
    }

    .about__text2 ol li:before {
        font-size: 14px;
    }

    .about__img-container {

        width: 25%;
    }

}

@media screen and (max-width: 960px) {
    .about__planet {
        width: 80px;
        height: 80px;
        top: -130%;
        left: 110%;
    }

    .about__photos {
        top: initial;
        bottom: -150%;
        left: 10%;
        width: 120px;
        height: auto;
    }



    .about__text1 h4::before {
        right: 40%;
        top: initial;
        bottom: -60%;
        transform: scale(0.5);
    }


    .about__subtitle span {
        display: none;
    }

    .about__subtitle h3 {
        font-size: 21px;
        line-height: 1.2em;
        margin-left: 0;
    }

    .about__body {
        flex-direction: column;
        margin-left: 10vw;
    }

    .about__text1 {
        padding: 20px 30px 0 20px;
        width: 400px;
    }

    .about__text1 div br {
        display: none;
    }

    .about__img-container {
        width: 150px;
        align-self: flex-start;
        margin-left: 330px;
        margin-top: 0em;
    }

    .about__text1::before {
        content: "";
        z-index: -1;
        position: absolute;
        border: 1px solid #515CD1;
        width: 100%;
        height: 205%;
        top: 0;
        left: 0;
        bottom: 0;
    }

    .about__text2 {
        align-self: flex-start;
    }

    .about__img-container .img {
        margin-top: 0;
    }

    .about__text1 .title__decor_arrow {
        transform: rotate(90deg);
        bottom: -100%;
        left: 40%;
        animation: fromTopAboutArrows 0.7s ease-out;
        animation-delay: 0.7s;
        animation-fill-mode: forwards;
    }

    @keyframes fromTopAboutArrows {
        0% {
            bottom: -100%;
            opacity: 0;
        }

        100% {
            opacity: 1;
            bottom: -200%;
        }
    }

}

@media screen and (max-width: 760px) {

    .about__planet {
        width: 41px;
        height: 41px;
        top: -120%;
        left: 335px;

    }

    .about__text1 {
        padding: 20px 30px 0 20px;
        width: 350px;
        align-self: flex-start;

    }

    .about__text1 div br {
        display: none;
    }

    .about__img-container {
        margin-top: 1em;
        width: 140px;
        align-self: flex-start;
        margin-left: 250px;
    }

    .about__text1::before {
        content: "";
        z-index: -1;
        position: absolute;
        border: 1px solid #515CD1;
        width: 100%;
        height: 205%;
        top: 0;
        left: 0;
        bottom: 0;
    }

    .about__text1 div {
        width: 100%;
        margin: 0;
        line-height: 1.1em;
        margin-top: 0.7em;
    }

    .about__text2 {
        width: 100%;
        padding: 0;
        margin: 0;
        margin-top: 2em;

    }
}

@media screen and (max-width: 752px) {
    .about__subtitle h3 br {
        display: block;
    }

}

@media screen and (max-width: 480px) {
    .about__planet {
        top: -130%;
        left: 68%;
    }

    .about__photos {

        width: 75px;
        height: 60px;
    }

    .about .h1-like-title {
        font-size: 28px;
        margin-bottom: 0.5em;
    }

    .about__wrapper {
        max-width: 85vw;
    }

    .about__subtitle h3 {
        font-weight: 700;
        font-size: 14px;
    }

    .about__body {
        margin-left: 0;
    }

    .about__text1 {
        padding: 20px 30px 0 20px;
        width: 60vw;
        align-self: flex-start;

    }

    .about__text1 div br {
        display: none;
    }

    .about__img-container {
        width: 140px;
        align-self: flex-start;
        margin-left: 40vw;
    }

    .about__text1::before {
        content: "";
        z-index: -1;
        position: absolute;
        border: 1px solid #515CD1;
        width: 100%;
        height: 205%;
        top: 0;
        left: 0;
        bottom: 0;
    }

    .about__text1 div {
        font-size: 12px;
        width: 100%;
        margin-top: 0.5em;
        line-height: 1.1em;
    }

    .about__text2 {
        width: 100%;
        padding: 0;
        margin: 0;
        margin-top: 2em;

    }

    .about__text2 ol li {
        font-size: 13px;
    }

    .about__text2 ol li::before {
        margin-right: 10px;
    }

    .about__text2 .button {
        width: 100%;
        margin: 0 auto;
    }


    .about h4,
    .about h4 span {
        font-size: 17px;
    }

    .about__subtitle h3 {
        line-height: 1.2em;
    }
}

@media screen and (max-width: 405px) {
    #spec {
        display: block;
    }
}

@media screen and (max-width: 360px) {
    .about__text1 {
        width: 220px;
    }

    .about__planet {
        top: -135%;
        left: 70%;
    }
}

@media screen and (max-width: 320px) {

    .about__planet {
        top: -125%;
        left: 75%;
    }

    .about .h1-like-title {
        font-size: 27px;
        margin-bottom: 0.5em;
    }

    .about__subtitle h3 {
        font-size: 13px;
    }

    .about__text2 ol li {
        font-size: 11px;
    }

    .about__body {
        margin-left: 0;
    }

    .about__img-container {
        width: 120px;
    }
}