.leave-alert {
    overflow: hidden;
    z-index: 20;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
}

.leave-alert__content {
    text-align: center;
    background-color: #ffffff;
    border-radius: 0;
    position: relative;
    display: block;
    margin: 1em 0;

    width: 500px;
    height: auto;

    
}

.leave-alert__content_inner {
    height: 100%;
    margin: 20px;
    padding: 20px;
    border: 1px solid #515CD1;
}

.leave-alert__close {
    cursor: pointer;
    position: absolute;
    width: 40px;
    height: 40px;
    border: none;
    border-radius: 50%;
    background: transparent;
    padding: 0;
    right: 20px;
    top: 20px;

}

.leave-alert__header {
    margin-top: 20px;
    display: inline;
    font-family: 'Krona One';
    font-size: 50px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    background: linear-gradient(104deg, #515CD1 0%, #FF5FAB 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.leave-alert__body {
    height: 100%;
    width: 100%;
    position: relative;
}


.leave-alert__body__intro {
    margin-top: 24px;
}

.leave-alert__body__intro span {
    font-family: 'Geometria';
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    background: linear-gradient(104deg, #515CD1 0%, #FF5FAB 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.leave-alert__body__text {
    margin-top: 24px;
    font-family: 'Geometria';
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
}

.leave-alert__body__input {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 24px;
}


.leave-alert__body__input input {
    padding: 8px 20px;
    width: 300px;
    border: 1px solid #515CD1;
}
.leave-alert__body__input .button {
    margin: 24px 0 16px;
    width: 240px;
    padding: 16px;
    font-family: 'Lato';
    font-size: 14px;
}

.leave-alert__body__img {
    margin: 0 -40px -43px -122px;
    width: 100%;
}




@media screen and (max-width: 1280px) {}

@media screen and (max-width: 1440px) {}

@media screen and (max-width: 980px) {}

@media screen and (max-width: 840px) {}

@media screen and (max-width: 640px) {
    .leave-alert__close {
        width: 40px;
        height: 40px;
    }
    .leave-alert__header {
        font-size: 30px;
    }
    .leave-alert__body__intro {
        margin-top: 12px;
    }
    .leave-alert__body__text {
        font-size: 16px;
    }
}

@media screen and (max-width: 520px) {
    .leave-alert__content {
        width: 100%;
    }
}

@media screen and (max-width: 420px) {

    .leave-alert__body__text {
        font-size: 14px;

    }
    .leave-alert__body__input input {
        width: 100%;
    }

}

@media screen and (max-width: 360px) {}

@media screen and (max-width: 360px) {
}