.intro {
    margin-top: 5em;
    margin-bottom: 7rem;
  }
  
  .intro__wrapper {
    position: relative;
  
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
  }
  
  .intro__main {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
  }
  
  .intro__card {
    position: relative;
    width: 47%;
  
    padding: 3em 0.5em 2em 0;
    border: 1px solid #515cd1;
  }
  
  .intro__card + .intro__card {
  }
  
  /*
  
  .intro__blogger::before {
      content: "";
      z-index: 1;
      position: absolute;
      width: 100%;
      height: 100%;
      top: -20px;
      left: -10px;
      border: 1px solid #515CD1;
  }
  */
  .intro__blogger {
  }
  
  .blogger.intro__card,
  .advertiser.intro__card {
    width: 75%;
  }
  
  .intro__advertiser {
    margin-top: 2em;
  }
  
  /*
  .intro__advertiser::before {
      content: "";
      z-index: 1;
      position: absolute;
      width: 100%;
      height: 100%;
      bottom: -20px;
      right: -10px;
      border: 1px solid #515CD1;
  }
  */
  .intro__card h3 {
    text-align: center;
    font-size: 1.06rem;
    line-height: 1rem;
    font-weight: 800;
    color: #515cd1;
    margin-bottom: 1em;
    letter-spacing: 0.05em;
  }
  
  .intro__card h2 {
    text-align: center;
    font-size: 2.9rem;
    font-weight: 500;
    letter-spacing: 0.03em;
    margin-bottom: 1em;
  }
  
  .intro__card-text {
    text-align: left;
    line-height: 1.2em;
    letter-spacing: 0.03em;
    margin-left: 3em;
  }
  
  .intro__card-text div {
    display: flex;
    align-items: center;
  }
  
  .intro__card-text div + div {
    margin-top: 10px;
  }
  
  .intro__card-text_decor {
    display: block;
    width: 0.65em;
    height: 0.65em;
    background-color: #f960ad;
    margin-right: 0.7em;
    margin-top: 0.3em;
    align-self: flex-start;
    padding: 5px;
  }
  
  .intro__advertiser .intro__card-text div:first-child .intro__card-text_decor {
    margin-right: 0.7em;
    width: 0.7em;
  }
  
  .intro__card-text_decor.purple {
    background-color: #515cd1;
  }
  
  .intro__footer {
    text-align: center;
    margin-top: 40px;
    width: 100%;
    background: rgba(96, 109, 255, 0.15);
    padding: 20px;
  }
  
  .intro__footer__inner {
    text-align: center;
    position: relative;
    border: 1px solid #515cd1;
  }
  .intro__footer__img {
    z-index: -1;
    position: absolute;
    bottom: -1px;
    left: -20px;
  }
  
  .intro__footer__inner h3 {
    font-weight: 700;
    margin-top: 40px;
  }
  
  .intro__footer .mobile {
    display: none;
  }
  
  @media screen and (max-width: 1600px) {
  }
  
  @media screen and (max-width: 1440px) {
    .intro__wrapper {
      max-width: 95vw;
    }
  }
  
  @media screen and (max-width: 1280px) {
    .intro__blogger {
      padding: 2em;
    }
  
    .intro__card {
    }
  
    .intro__card-text {
    }
  
    .intro__blogger .intro__card-text {
      width: 90%;
      margin-left: 1em;
    }
  
    .intro__advertiser {
    }
  
    .intro__card h2 {
      font-size: 2em;
      margin-bottom: 0.8em;
    }
  
    .intro__card h3 {
      font-size: 0.8em;
      margin-bottom: 0.8em;
    }
  }
  
  @media screen and (max-width: 960px) {
    .intro__wrapper {
      max-width: 90vw;
    }
    .intro__main {
      flex-direction: column;
      align-items: center;
    }
  
    .blogger.intro__card,
    .advertiser.intro__card {
      width: 100%;
    }
  
    .intro__blogger {
      align-self: flex-start;
    }
  
    .intro__advertiser {
      align-self: flex-end;
    }
  
    .intro__card {
      margin-left: 2em;
      margin-right: 2em;
      width: 400px;
    }
  
    .intro__footer__inner {
      display: none;
    }
  
    .intro__footer {
      background: transparent;
    }
  
    .intro__footer .mobile {
      display: initial;
    }
  }
  
  @media screen and (max-width: 760px) {
    .intro {
      margin-bottom: 0;
    }
  
    .intro__card {
      margin-left: 1em;
      margin-right: 1em;
      width: 400px;
    }
  
    .intro__card-text {
      margin: 0 2em;
    }
  
    .blogger.intro__card h2,
    .advertiser.intro__card h2 {
      font-size: 20px;
    }
  }
  
  @media screen and (max-width: 480px) {
    .intro {
      margin-bottom: 0;
    }
  
    .intro__card {
      align-self: center;
      margin-left: 0;
      margin-right: 0;
      width: 100%;
      padding-left: 0.7em;
      padding-right: 0.2em;
    }
  
    .blogger.intro__card h2,
    .advertiser.intro__card h2 {
      font-size: 16px;
    }
  
    .intro__card-text {
      font-size: 14px;
      margin: 0 !important;
      width: 100% !important;
    }
  
    .intro__blogger .intro__card-text {
      margin: 0 16px;
    }
  
    .intro__blogger h2 {
      font-size: 30px;
    }
  
    .intro__card h3 {
      font-size: 12px;
    }
  
    .intro__advertiser h2 {
      font-size: 25px;
    }
  }
  
  @media screen and (max-width: 320px) {
  }
  