.swiper {
    position: initial;
    width: 100%;
}

.swiper-container {
    width: 90%;
}

.swiper-slide {
    text-align: center;
    width: auto;
}

.swiper-button-prev,
.swiper-button-next {
    width: 20px;
    height: 20px;
    border-top: 2px solid #111111;
    border-right: 2px solid #111111;
    position: absolute;
    z-index: 10;
    top: 75%;
    color: #111111;
    cursor: pointer;
}

.swiper-button-prev::after,
.swiper-button-next::after {
    content: " ";
}

.swiper-button-prev {
    transform: rotate(-135deg);
    left: 0px;
    right: auto;
}

.swiper-button-next {
    transform: rotate(45deg);
    right: 0px;
    left: auto;
}

@media screen and (max-width: 900px) {
    .swiper-button-prev,
.swiper-button-next {
    width: 16px;
    height: 16px;
 
}
}