.calculator {
    margin-top: 9.5em;
}

.calculator__wrapper {
    position: relative;
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3em 12em 3em 8em;
}

.calculator__wrapper::before {
    content: " ";
    z-index: -1;
    position: absolute;
    border: 1px solid #FF5FAB;
    width: 100vw;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
}

.calculator__header {
    margin-bottom: 1em;
    width: 50%;
}

.calculator__header h2 span {
    display: block;
    text-align: left;
    /* or 117% */

    letter-spacing: 0.03em;
    text-transform: uppercase;

    color: #111111;
}

.calculator__body {
    width: 50%;
    margin-left: 10em;

}

.calculator input[type=range] {
    --value: 20000;
    --min: 1000;
    --max: 50000;
}

input[type=range] {
    margin: 2em 0;
    height: 10px;
    width: 100%;
    -webkit-appearance: none;
    cursor: pointer;
  }
  
  input[type=range] {
    --range: calc(var(--max) - var(--min));
    --ratio: calc((var(--value) - var(--min)) / var(--range));
    --sx: calc(0.5 * 10px + var(--ratio) * (100% - 10px));
  }
  
  input[type=range]:focus {
    outline: none;
  }
  

  input[type=range]::-webkit-slider-thumb {
    width: 40px;
    height: 40px;
    border: 1px solid #515CD1;
    border-radius: 50%;
    background: #FFFFFF;
    border: none;
    box-shadow: 0 0 5px #515CD1;
    margin-top: calc(-7px * 0.5 - 10px * 0.5);
    -webkit-appearance: none;
  }
  
  input[type=range]::-webkit-slider-runnable-track {
    margin-bottom: 0px;
    height: 23px;
    border-radius: 100px;
    background: #E0E0E0;
    border: none;
    box-shadow: none;
  }

  input[type=range]::-webkit-slider-thumb:hover {
    background: #515CD1;
  }

  input[type=range]::-webkit-slider-thumb:active {
    background: #515CD1;
  }

  input[type=range]::-webkit-slider-runnable-track {
    background: linear-gradient(#515CD1,#515CD1) 0/var(--sx) 100% no-repeat, #efefef;
  }
  


/*



.calculator input[type='range'] {
    margin-top: 1.5em;
    margin-bottom: 3.5em;
    display: block;
    -webkit-appearance: none ;
    
    background: #E0E0E0;
    border: 1px solid #515CD1;
    border-radius: 100px;
    width: 100%;
    height: 1.5em;
}

.calculator input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none ;
    background: #FFFFFF;
    border: 1px solid #515CD1;
    border-radius: 50%;
    width: 3em;
    height: 3em;
    
}


.calculator input[type="range"]::-webkit-progress-value { background-color: orange; }

.calculator input[type="range"]::-moz-range-progress {
    background-color: #515CD1;
}

.calculator input[type="range"]::-moz-range-track {
    background-color: #E0E0E0;
}

.calculator input[type="range"]::-ms-fill-lower {
    background-color: #515CD1;
}

.calculator input[type="range"]::-ms-fill-upper {
    background-color: #E0E0E0;
}

*/

.calculator__input {
    display: flex;
    justify-content: space-between;
}

.calculator__input>span {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 44px;
    /* identical to box height, or 259% */

    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
}

.calculator__input span.purple {
    text-align: right;
}


.calculator__result {
    background: #FFFFFF;
    border: 1px solid #1C1C1C;
    padding: 1.4em 0;
    margin-bottom: 3em;
}

.calculator__result div {
    font-weight: 700;
    font-size: 28px;
    line-height: 44px;

    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;

    color: #1C1C1C;
}

.calculator__result span {
    font-family: monospace;
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 70px;

    letter-spacing: 0.03em;
    text-transform: uppercase;

    background: linear-gradient(272.73deg, #515CD1 1.3%, #FF5FAB 100%), linear-gradient(90deg, #00A3FF -7.48%, #69F2C9 107.28%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.calculator__result .rub {
    font-family: 'Geometria';
    font-weight: 500;
    color: #515CD1;
    background: none;
    font-size: 0.9em;
}

.calculator .button.mobile {
    display: none;
}

.calculator input.blogger {
    width: 100%;
    margin-bottom: 2em;
    padding: 1em;
    font-size: 1em;
    line-height: 1em;
    letter-spacing: 0.05em;
    text-transform: uppercase;

    border: 1px solid #515CD1;
}

@media screen and (max-width: 1440px) {
    .calculator__wrapper {
        max-width: 90vw;
        padding-right: 8em;
    }



    .calculator__result span {
        font-size: 45px;
    }

 

    .calculator__result div {
        font-size: 1.5em;
    }

    .calculator__body {
        margin-left: 8em;
    }
}


@media screen and (max-width: 1280px) {
    .calculator__wrapper {
        padding-right: 4.5em;
        padding-left: 5em;
    }

    .calculator__result span {
        font-size: 43px;
    }

    .calculator__result div {
        font-size: 1.2em;
    }

    .calculator__body {
        margin-left: 5em;
    }
}

@media screen and (max-width: 960px) {

    .calculator .button.mobile {
        display: initial;
        margin-top: 2em;
    }

    .calculator .button.desktop {
        display: none;
    }

    .calculator__wrapper {
        flex-direction: column;
        align-items: center;
    }

    .calculator__wrapper::before {
        border: none;
        border-top: 1px solid #FF5FAB;
        border-bottom: 1px solid #FF5FAB;
        width: 125vw;
        height: 100%;
        top: 0;
        left: -25vw;
        bottom: 0;
    }

    .calculator__header {
        width: 100%;
        align-self: center;
    }

    .calculator__header h2 span {
        text-align: center;
    }

    .calculator__body {
        width: 70%;
        margin-left: 0;
        align-self: center;
    }


    .calculator__result {
        border: none;
        margin: 0;
        padding: 0;
    }

    .calculator__result div {
        font-size: 20px;
    }

    .calculator__result span {
        font-size: 45px;
    }
}

@media screen and (max-width: 760px) {
    .calculator__header h2 span {
        font-size: 30px;
        line-height: 1.2em;
    }

    .calculator__body {
        width: 100%;
    }
}

@media screen and (max-width: 480px) {
    .calculator__wrapper {

        max-width: 80vw;
        padding-left: 0;
        padding-right: 0;
    }






    .calculator input[type='range'] {
        margin-bottom: 2em;
    }

    .calculator__result div {
        line-height: 1.2em;
    }

    .calculator__result span {
        font-size: 40px;
    }

    .calculator__input>span {
        font-size: 12px;
    }


}

@media screen and (max-width: 320px) {}