@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Krona+One&display=swap");
@import url('https://fonts.cdnfonts.com/css/geometria');

@font-face {
  font-family: 'Neue Machina';
  src: url('./fonts/NeueMachina/NeueMachina-Bold.woff') format('woff');
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('./fonts/Lato/latomedium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Lato';
  src: url('./fonts/Lato/latomediumitalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

html {
  scroll-behavior: smooth;
  font-size: 17px;
  overflow: auto;
}

.relative_z-2 {
  position: relative;
  z-index: 2;
}

.spinner {
  z-index: 101;
  position: absolute;
  top: calc(50% - 20px);
  left: calc(50% - 20px);
  width: 40px;
  height: 40px;
  border: 5px solid rgba(0, 0, 0, 0.2);
  border-left: 5px solid #515CD1;
  border-radius: 50%;
  animation: load8 1.1s infinite linear;
  transition: opacity 0.3s;
}

.spinner div {
  z-index: 101;

  width: 40px;
  height: 40px;
  border: 5px solid rgba(0, 0, 0, 0.2);
  border-left: 5px solid #515CD1;
  border-radius: 50%;
  animation: load8 1.1s infinite linear;
  transition: opacity 0.3s;
}

.loader__bg {
  overflow: hidden;
  z-index: 100;
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: #ffffff;
  top: 0;
  left: 0;
}

.loader {
  z-index: 101;
  position: absolute;
  top: calc(50% - 20px);
  left: calc(50% - 20px);
  width: 40px;
  height: 40px;
  border: 5px solid rgba(0, 0, 0, 0.2);
  border-left: 5px solid #515CD1;
  border-radius: 50%;
  animation: load8 1.1s infinite linear;
  transition: opacity 0.3s;
}


.loader__hide {
  opacity: 0;
  display: none;
}

@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

body {
  width: 100%;
  height: 100%;

  overflow: initial;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 500;
  color: #1C1C1C;


  background-image: url('./assets/bg.webp');


}


h1 {
  font-family: 'Krona One', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 77px;
  line-height: 1.25em;
  color: #FFFFFF;


  -webkit-text-stroke: 2px #515CD1;
  filter: drop-shadow(1px 3px 3px rgba(89, 93, 208, 0.8));
}

.h1-like-title {
  font-family: 'Krona One', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 77px;
  line-height: 1.25em;
  color: #FFFFFF;


  -webkit-text-stroke: 2px #515CD1;
  filter: drop-shadow(1px 3px 3px rgba(89, 93, 208, 0.8));

}


h2 {
  font-family: 'Geometria', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 60px;
  line-height: 1.15em;
  text-transform: uppercase;
  letter-spacing: 0.01em;

  color: #1C1C1C;
}

h3 {
  font-style: normal;
  font-weight: 900;
  font-size: 35px;
  line-height: 44px;
  text-transform: uppercase;

  color: #1C1C1C;
}

.d-none {
  display: none;
}

.d-none-important {
  display: none !important;
}

.row {
  display: flex;
}

.column {
  display: flex;
  flex-direction: column;
}

.flex-direction-column {
  flex-direction: column;
}

.space-between {
  justify-content: space-between;
}

.space-around {
  justify-content: space-around;
}

.space-evenly {
  justify-content: space-evenly;
}

.column-reverse {
  flex-direction: column-reverse !important;
}

.right {
  justify-content: flex-end;
}

.v-center {
  align-items: center;
}

.center {
  justify-content: center;
}

.flex-wrap {
  flex-wrap: wrap;
}


.purple {
  color: #515CD1 !important;
}

.pink {
  color: #FF5FAB !important;
}

.text_gradient {
  background: linear-gradient(89.76deg, #515CD1 0.21%, #FF5FAB 99.79%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.img {
  max-width: 100%;
  height: auto;
}

.num {
  font-family: 'Neue Machina', monospace;
  font-style: normal;
  font-weight: 700;
}

.text_footnote {
  font-size: 0.65em;
  color: #3f3f3f;
}


@media screen and (max-width: 1440px) {
  html {
    font-size: 16px;
  }

  h1,
  .h1-like-title {
    font-size: 65px;
  }

  h2 {
    font-size: 55px;
  }
}


@media screen and (max-width: 1280px) {
  html {
    font-size: 16px;
  }

  h1,
  .h1-like-title {
    font-size: 55px;
  }

  h2,
  h2 span {
    font-size: 45px;
  }

  h3,
  h3 span {
    font-size: 30px;
  }

}

@media screen and (max-width: 960px) {
  html {
    font-size: 15px;
  }

  h1,
  .h1-like-title {
    -webkit-text-stroke: 1px #515CD1;
    font-size: 45px;
  }

  h2,
  h2 span {
    font-size: 40px;
  }

  h3,
  h3 span {
    font-size: 25px;
  }


}

@media screen and (max-width: 760px) {
  html {}

  h1,
  .h1-like-title {
    font-size: 40px;
  }

  h2,
  h2 span {
    font-size: 24px;
  }

  h3,
  h3 span {
    font-size: 20px;
    line-height: 20px;
  }
}

@media screen and (max-width: 480px) {
  html {
    font-size: 14px;
  }

  h1,
  .h1-like-title {
    font-size: 27px;
  }

  h2,
  h2 span {
    font-size: 25px;
  }
}

@media screen and (max-width: 320px) {
  html {
    font-size: 14px;
  }

  h2,
  h2 span {
    font-size: 22px;
  }
}