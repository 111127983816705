.title {
    height: 100%;
    position: relative;
    
}



.title__light {
    width: 100%;
    z-index: -1;
    position: absolute;
    content: url('../assets/light_title.svg');
    top: 0%;
    right: -22%;
    background-blend-mode: overlay;
}

.title__wrapper {
    width: 100%;
    max-width: 1520px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.title__container {
    display: flex;
    justify-content: space-between;
}

.title__btns-block {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
}

.title__img-container {
    align-self: flex-start;
    margin-right: -1.5em;
    width: 700px;
}

.title__img-container .img {
    visibility: hidden;
}

.title__img-container .img.animate {
    animation: fromRight 0.5s ease-out;
    animation-delay: 0.7s;
    animation-fill-mode: forwards;
    will-change: transform;
    opacity: 0;
}

@keyframes fromRight {
    0% {
        opacity: 0;
        transform: translateX(10%);
    }

    100% {
        visibility: visible;
        opacity: 1;
        transform: translateX(0);
    }
}

.title__img_preloader-container {
    width: 30%;
    position: relative;
}

.title__img-container img {}

.blogger.title__img-container {}

.advertiser.title__img-container {
    margin-right: -2em;
}

.title__content {

    display: flex;
    flex-direction: column;
    align-self: flex-start;
    margin-top: 3em;
    margin-left: 3.5em;
}

.mobile.title__btns-block {
    display: none;
    margin-top: 2em;
}

.mobile.title__btns-block .title__btns {
    flex-direction: column;
}

.mobile.title__btns-block .title__btns *+* {
    margin: 0;
    margin-top: 1em;
}

.title__headers {
   position: relative;
    margin-bottom: 4em;
    text-align: left;
}

.title__headers h1 {
    margin-bottom: 0.35em;
}

.title__headers h2 {
    
    margin-left: 0.1em;
    font-size: 58px;
    letter-spacing: 0.025em;
}

.title__unite {
    overflow: hidden;
    display: flex;
    align-items: center;
    text-align: left;
    font-size: 50px;
    margin-left: 0.5rem;
    margin-bottom: 1.1em;
}

.title__unite-text {

    font-family: 'Lato', sans-serif;
    font-weight: 700;
    line-height: 60px;

    letter-spacing: 0.06em;
    text-transform: uppercase;

    background: linear-gradient(89.76deg, #515CD1 0.21%, #FF5FAB 99.79%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;

    animation: opacitySlideFadingInOut 2s ease-out infinite;

}

@keyframes opacitySlideFadingInOut {
    0% {
        transform: translateY(-100%);
        opacity: 0;
    }

    10% {
        transform: translateY(-10%);
        opacity: 0;
    }

    20% {
        transform: translateY(0%);
        opacity: 1;
    }

    50% {
        transform: translateY(0%);
        opacity: 1;
    }

    80% {
        transform: translateY(0%);
        opacity: 1;
    }

    90% {
        transform: translateY(10%);
        opacity: 0;
    }

    100% {
        transform: translateY(100%);
        opacity: 0;
    }
}


.title__unite-decor {
    display: block;
    width: 0.6em;
    height: 0.6em;
    background: #FF5FBF;
    margin-right: 0.5em;
}

.title__decor_arrow {
    position: absolute;
    width: 60px;
    height: 30px;
    bottom: -28%;
    right: 7%;
    opacity: 0;

    animation: fromLeftTitleArrows 1s ease-out;
    animation-delay: 0.7s;
    animation-fill-mode: forwards;
}

@keyframes fromLeftTitleArrows {
    0% {
        right: 40%;
        opacity: 0;
    }

    100% {
        opacity: 1;
        right: 7%;
    }
}



.title__btns {
    width: 100%;
    display: flex;
    align-self: start;
    padding-left: 0.4em;
}


.title__btns *+* {
    margin-left: 1.7em;
}

.title__calc-btn {
    cursor: pointer;
    align-self: flex-start;
    margin-top: 2.6rem;
    margin-left: 0.5rem;
    font-size: 17px;
    line-height: 20px;
    letter-spacing: 0.05em;
    text-transform: uppercase;

    padding-bottom: 0.5em;
    border-bottom: 1px solid #7D7D7D;

    color: #7D7D7D;
    transition: all 0.2s linear;
}

.title__calc-btn:hover {
    color: #111111;
    border-bottom-color: #111111;
}

.title__stats {
    
    width: 100%;
    margin: 0 auto;
    padding: 1em 0em;
    margin-top: 139px;

    position: relative;
    display: flex;
    justify-content: center;
    color: #FFFFFF;
}

.blogger.title__stats .title__stats__border,  .advertiser.title__stats .title__stats__border{
    height: 40px;
}

.title__stats__border {
    border-left: 1px solid #FFFFFF;
    height: 88px;
    width: 1px;
    align-self: center;
    padding: 0 !important;
}

.title__stats>div {
    padding: 0.5em 5em;
}

.title__stats::before {
    content: " ";
    position: absolute;
    z-index: -1;
    width: 100vw;
    height: 100%;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);

    background: linear-gradient(92.14deg, #515CD1 2.11%, #FF5FAB 100%);
}



.title__stats__num {
    font-family: 'Geometria', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 60px;
    line-height: 40px;
    text-align: center;
    letter-spacing: 0.02em;
    margin-bottom: 0.35em;
}

.title__stats__income {
    display: inline;
    border: 1px solid #FFFFFF;
    font-size: 16px;
    padding: 4px 10px;
}

.title__stats__text {
    margin-top: 1em;
    font-size: 13px;
}

.blogger.title__stats,
.advertiser.title__stats {
    align-items: center;
}

.blogger.title__stats .title__stats__text,
.advertiser.title__stats .title__stats__text {
    margin-top: 0;
    font-size: 0.8rem;
    line-height: 1rem;
}

.blogger.title__stats>div,
.advertiser.title__stats>div {
    padding-left: 55px;
    padding-right: 55px;
}

.title__decor_rect::before {
    position: absolute;
    content: " ";
    z-index: -1;
    width: 6.2em;
    height: 0.65em;
    background-color: #FF5FBF;
    margin-left: -0.5em;
    bottom: -4%;
    opacity: 0;
    animation: fromLeftTitleRect 0.7s ease-out;
    animation-delay: 0.3s;
    animation-fill-mode: forwards;
}

.advertiser .title__decor_rect::before {
    margin-left: -0.8em;
}

@keyframes fromLeftTitleRect {
    0% {
        left: 0;
        opacity: 0;
    }

    100% {
        opacity: 1;
        left: 22%;
    }
}

/* BLOGGER */
.blogger.title__headers h2 {
    font-size: 58px;
    letter-spacing: 0.01em;
}

/* ADVERTISER */
.advertiser.title__headers h2 {
    font-size: 54px;
    letter-spacing: 0.01em;
}


@media screen and (max-width: 1900px) {
    .title__stats {
        margin-top: 3em;
    }
}

@media screen and (max-width: 1600px) {

    .title__img-container img {
        width: 600px;
    }

    .title__headers h2 {
        font-size: 54px;
    }

    .blogger.title__headers h2,
    .blogger.title__headers h2 span {
        font-size: 54px;
    }

    .advertiser.title__headers h2,
    .advertiser.title__headers h2 span {
        font-size: 49px;
        letter-spacing: 0.01em;
    }
}

@media screen and (max-width: 1440px) {
    .title__decor_rect::before {
        width: 280px;
        bottom: -5%;
    }

    .title__wrapper {
        max-width: 90vw;
    }

    .title__img-container {
        z-index: -1;
        align-self: center;
        width: 80%;
    }

    .blogger.title__img-container {
        width: 500px;
    }

    .advertiser.title__img-container {
        width: 500px;
    }

    .title__headers h2 {
        font-size: 45px;
    }

    .blogger.title__headers h2,
    .blogger.title__headers h2 span {
        font-size: 44px;
    }

    .advertiser.title__headers h2,
    .advertiser.title__headers h2 span {
        font-size: 40px;
        letter-spacing: 0.01em;
    }

    .title__stats__num {
        font-size: 55px;
    }

}


@media screen and (max-width: 1280px) {
    .title__decor_rect::before {
        width: 250px;
        height: 25px;
    }

    .blogger .title__decor_rect::before {
        width: 230px;
        height: 23px;
        bottom: -8%;
        margin-left: -0.51em;
    }

    .title__content {
        margin-left: 1em;
    }

    .title__img-container {
        margin-top: 1em;

    }

    .title__headers h1 {
        margin-bottom: 0em;
    }

    .title__headers h2 {
        font-size: 38px;
    }

    .blogger.title__headers h2,
    .blogger.title__headers h2 span {
        font-size: 33px;
    }

    .blogger.title__img-container {
        width: 400px;
        margin-left: 1em;
    }

    .advertiser.title__img-container {
        width: 400px;
        margin-left: 1em;
    }

    .advertiser.title__headers h2,
    .advertiser.title__headers h2 span {
        font-size: 33px;
        letter-spacing: 0.01em;
    }

    .title__unite {
        font-size: 40px;
    }

    .title__stats>div {
        padding: 0 3em;
    }

    .title__stats__num {
        font-size: 45px;
    }

    .blogger.title__stats>div,
    .advertiser.title__stats>div {
        padding-left: 10px;
        padding-right: 20px;
    }

}

@media screen and (max-width: 960px) {
    .title__decor_rect::before {
        width: 200px;
        height: 20px;
    }

    .blogger .title__decor_rect::before {
        width: 180px;
        height: 18px;
        margin-left: -0.51em;
    }

    @keyframes fromLeftTitleRect {
        0% {
            left: 0;
            opacity: 0;
        }

        100% {
            opacity: 1;
            left: 21%;
        }
    }


    .title__headers h2 {
        font-size: 30px;
    }

    .blogger.title__headers h2,
    .blogger.title__headers h2 span {
        font-size: 26px;
    }

    .advertiser.title__headers h2,
    .advertiser.title__headers h2 span {
        font-size: 28px;
        letter-spacing: 0.01em;
    }

    .title__unite {
        font-size: 30px;
    }

    .title__calc-btn {
        margin-top: 2em;
        font-size: 15px;
    }

    .title__stats>div {
        padding: 0 3.2em;
    }

    .blogger.title__stats .title__stats__text,
    .advertiser.title__stats .title__stats__text {
        font-size: 0.7em;
    }
}

@media screen and (max-width: 760px) {
    .title__img_preloader-container {
        position: relative;
        min-width: 80vw;
        min-height: 80vw;
    }

   
    .title__light::before {
        left: initial;
        top: 0%;
        right: 0%;
        width: 70%;

    }

    .title__decor_rect::before {
        width: 200px;
        margin-left: -1.8em;
    }

    .blogger .title__decor_rect::before {
        width: 160px;
        height: 18px;
        margin-left: -1.5em;
    }

    .advertiser .title__decor_rect::before {
        width: 160px;
        height: 18px;
        bottom: -8%;
        margin-left: -2.1em;
    }

    @keyframes fromLeftTitleRect {
        0% {
            left: 0;
            opacity: 0;
        }

        100% {
            opacity: 1;
            left: 44%;
        }
    }

    .title__decor_arrow {
        width: 30px;
        height: 15px;
        right: 7%;
        bottom: -35%;
        transform: rotate(90deg);
    }

    @keyframes fromLeftTitleArrows {
        0% {
            opacity: 0;
            bottom: 0;
        }

        100% {
            opacity: 1;
            bottom: -35%;
        }
    }

    .title__img-container {
        width: 80%;
        margin-right: 0;
    }


    .blogger.title__img-container {
        margin-left: 0;
        
    }

    .blogger.title__img-container {
        width: auto;
        max-width: 90%;
    }


    .title__content {
        margin-left: 0;
        align-items: center;
        width: 100%;
    }

    .title__headers {
        text-align: center;
        width: 100%;
        margin-bottom: 1em;
    }

    .title__headers h2 {
        margin-left: 0;
    }

    .blogger.title__headers h2,
    .blogger.title__headers h2 span {
        font-size: 25px;
    }

    .advertiser.title__headers h2,
    .advertiser.title__headers h2 span {
        font-size: 25px;
    }

    .title__container {
        flex-direction: column;
    }

    .desktop.title__btns-block {
        display: none;
    }

    .mobile.title__btns-block {
        display: flex;
    }

    .title__unite {
        align-self: flex-start;
        font-size: 25px;
        margin-left: 1.5em;
        margin-bottom: 0;
    }

    .title__headers h2 {
        margin-top: 0.8em;
        font-size: 30px;
    }

    .title__calc-btn {
        text-align: center;
        align-self: center;
        margin-top: 2em;
        font-size: 18px;
    }

    .title__stats__num {
        font-size: 1.6em;
    }

    .title__stats>div {
        padding: 0 0.7em;
    }

    .title__stats__text {
        margin-top: 1.1em;
    }

    .title__stats__income {
        font-size: 0.9em;
    }

    .title__stats {
        flex-direction: column;
        padding: 70px 38px;
    }

    .title__stats::before {
        width: 100vw;
        height: 100%;
        top: 0;
        left: 0;
        transform: none;
        background: linear-gradient(180deg, #515CD1 0%, #FF5FAB 100%);
    }

    .title__stats__border {
        border-bottom: 1px solid #FFFFFF;
        border-left: none;
        height: 1px;
        width: 90%;
        align-self: center;
        padding-top: 30px !important;
        margin-bottom: 30px;
    }

    .title__stats__num {
        font-size: 57px;
        margin-bottom: 0.4em;
    }

    .title__stats>div {
        padding: 0 0.6em;
    }

    .title__stats__text {
        font-size: 12px;
        line-height: 14px;
        font-weight: 400;
        margin-top: 1.5em;
        letter-spacing: 0.02em;
    }

    .title__stats__income {
        font-size: 17px;
    }

    .blogger.title__stats .title__stats__text,
    .advertiser.title__stats .title__stats__text {
        font-size: 1.3em;
        line-height: 1.2em;
    }

}

@media screen and (max-width: 480px) {
    .title__decor_rect::before {
        width: 160px;
        height: 20px;
        bottom: -10%;
        margin-left: -1.6em;
    }

    .blogger .title__decor_rect::before {
        width: 120px;
        height: 14px;
        margin-left: -1.4em;
    }

    .advertiser .title__decor_rect::before {
        width: 120px;
        height: 15px;
        
        margin-left: -1.92em;
    }

    @keyframes fromLeftTitleRect {
        0% {
            left: 0;
            opacity: 0;
        }

        100% {
            opacity: 1;
            left: 41%;
        }
    }

    .title__decor_arrow {
        width: 30px;
        height: 15px;
        right: 7%;
        bottom: -35%;
        transform: rotate(90deg);
    }

    @keyframes fromLeftTitleArrows {
        0% {
            opacity: 0;
            bottom: 0;
        }

        100% {
            opacity: 1;
            bottom: -35%;
        }
    }

    .title__content {
        width: 100%;
        margin-left: 0em;
        margin-top: 2em;
        text-align: center;
    }

    .title__headers {
        margin-bottom: 1em;
        text-align: center;
    }

    .title__headers h1 {
        margin-bottom: 0;
    }

    .title__headers h2 {
        font-size: 26px;
        margin-top: 0.5em;
    }

    .blogger.title__headers h2,
    .blogger.title__headers h2 span {
        font-size: 25px;
    }

    .advertiser.title__headers h2,
    .advertiser.title__headers h2 span {
        font-size: 23px;
    }

    .title__unite {
        font-size: 20px;
    }

    .title__btns {
        width: 100%;
    }

    .title__calc-btn {
        align-self: center;
        font-size: 13px;
    }

    .blogger.title__stats,
    .advertiser.title__stats {
        padding: 2em 1.5em;
    }

    .blogger.title__stats div,
    .advertiser.title__stats div {
        padding: 0;
    }

    .blogger.title__stats .title__stats__text,
    .advertiser.title__stats .title__stats__text {
        font-size: 1em;
        line-height: 1.2em;
    }

    .title__stats__border {
        padding-top: 20px !important;
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 360px) {
    .title__decor_rect::before {
        width: 140px;
        height: 16px;
        margin-left: -1.65em;
    }
    .title__btns *+* {
        margin: 0;
    }

    .title__btns * {
        width: 90%;
    }

    .title__btns *+* {
        margin-top: 1em;
    }

    .title__btns {
        align-items: center;
        flex-direction: column;
    }

    .title__headers h2 {
        font-size: 23px;
    }

    .blogger.title__headers h2,
    .blogger.title__headers h2 span {
        font-size: 20px;
    }

    .advertiser.title__headers h2,
    .advertiser.title__headers h2 span {
        font-size: 22px;
    }

    .title__calc-btn {
        font-size: 11px;
        padding-bottom: 0em;
    }
}


@media screen and (max-width: 320px) {}